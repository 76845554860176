import $ from "jquery"; // required by Masonry
import Wallop from "../lib/Wallop.js";
import Masonry from "../lib/masonry.min.js";
import imagesLoaded from "../lib/imagesloaded.min.js";
import cookieconsent from "../lib/cookieconsent.min.js";
import Choices from "../lib/choices.min.js";
import "../scss/index.scss";
import AppController from "./app_controller";
import MenuController from "./menu_controller";
import { Application } from "@hotwired/stimulus";
import '../lib/lite-yt-embed';

const Stimulus = Application.start();

Stimulus.register('app', AppController);
Stimulus.register('menu', MenuController);

window.addEventListener("load", function () {
  let customContent;
  if (window.location.pathname.startsWith("/de/")) {
    customContent = {
      message:
        "Diese Website nutzt Cookies, um die bestmögliche Online-Erfahrung für dich sicherzustellen.",
      link: "Mehr Erfahren",
      dismiss: "Verstanden",
      href: "/de/cookie-policy/",
    };
  } else if (window.location.pathname.startsWith("/fr/")) {
    customContent = {
      message:
        "Ce site utilise des cookies pour garantir la meilleure expérience à nos visiteurs",
      link: "En savoir plus",
      dismiss: "Compris",
      href: "/fr/cookie-policy/",
    };
  } else {
    customContent = {
      href: "/cookie-policy/",
    };
  }
  this.window.cookieconsent.initialise({
    palette: {
      popup: {
        background: "#edeff5",
        text: "#838391",
      },
      button: {
        background: "#f74349",
      },
    },
    content: customContent,
  });
});

/*
/* Helper functions */
/*******************/

var throttle = (function () {
  return function (fn, delay) {
    delay || (delay = 250);
    var last = +new Date();
    return function () {
      var now = +new Date();
      if (now - last > delay) {
        fn.apply(this, arguments);
        last = now;
      }
    };
  };
})();

function ready(fn) {
  if (document.readyState != "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

var forEach = function (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes object back
  }
};

function hasClass(element, className) {
  return new RegExp("(\\s|^)" + className + "(\\s|$)").test(element.className);
}

function addClass(element, className) {
  if (!element) {
    return;
  }
  if (!hasClass(element, className)) {
    element.className =
      element.className.replace(/\s+$/gi, "") + " " + className;
  }
}

function removeClass(element, className) {
  if (!element) {
    return;
  }
  if (hasClass(element, className)) {
    element.className = element.className.replace(className, "");
  }
}

/*
/* Init */
/*******************/

var $b = document.getElementsByTagName("body")[0];

/*
/* Carousel */
/*******************/

var carousel_config = {
    buttonPreviousClass: "carousel-previous",
    buttonNextClass: "carousel-next",
    itemClass: "carousel-item",
    currentItemClass: "carousel-item-current",
    showPreviousClass: "carousel-show-previous",
    showNextClass: "carousel-show-next",
    hidePreviousClass: "carousel-hide-previous",
    hideNextClass: "carousel-hide-next",
    carousel: true,
  },
  $carousels = document.querySelectorAll(".carousel");

ready(function () {
  let $carousel_navs = [];

  if ($carousels.length > 0) {
    forEach($carousels, function (i, $carousel) {
      var carousel = new Wallop($carousel, carousel_config),
        carousel_id = $carousel.getAttribute("id");
      if (carousel_id !== null) {
        $carousel_navs[carousel_id] = document.querySelectorAll(
          '.carousel-nav[data-target="' + carousel_id + '"]'
        );

        if ($carousel_navs[carousel_id].length > 0) {
          $carousel_navs[carousel_id]["links"] = document.querySelectorAll(
            '.carousel-nav[data-target="' + carousel_id + '"] a'
          );

          forEach($carousel_navs[carousel_id]["links"], function (i, navEl) {
            navEl.addEventListener("click", function (e) {
              e.preventDefault();
              forEach($carousel_navs[carousel_id]["links"], function (i, item) {
                removeClass(item, "is-active");
              });
              addClass(navEl, "is-active");
              carousel.goTo(i);
            });
          });
        }
      }
    });
  }
});

/*
/* Tabs */
/*******************/

ready(function () {
  var tagButton = document.querySelector(".tag-button"),
    tags = [].slice.call(document.querySelectorAll(".tag-nav"));

  if (tagButton !== null) {
    // addClass(tagButton, 'is-hiding');
    // forEach(tags, function(i,tag){addClass(tag, 'is-hidden')});
    tagButton.addEventListener("click", function (event) {
      if (hasClass(tagButton, "is-hiding")) {
        forEach(tags, function (i, tag) {
          removeClass(tag, "is-hidden");
        });
        forEach(tags, function (i, tag) {
          addClass(tag, "is-shown");
        });
        addClass(tagButton, "is-showing");
        removeClass(tagButton, "is-hiding");
      } else {
        forEach(tags, function (i, tag) {
          removeClass(tag, "is-shown");
        });
        forEach(tags, function (i, tag) {
          addClass(tag, "is-hidden");
        });
        addClass(tagButton, "is-hiding");
        removeClass(tagButton, "is-showing");
      }
    });
  }

  // var tag_filters = document.querySelectorAll('.tag-filters .tags input[type="checkbox"]');

  // if(tag_filters.length > 0) {
  //   forEach(tag_filters, function (i, tag) {
  //     tag.addEventListener('change', function(e) {
  //       tag.form.submit();
  //     })
  //   });
  // }

  /*
/* Anchor scroll */
  /*******************/

  function AnchorScrollTo(name) {
    let targetEle;
    targetEle = document.getElementById(name);
    // account for menu
    let theTop;
    theTop = -80;
    // account for promo-strip
    if (document.getElementsByClassName("promo-strip")[0]) {
      theTop -= 70;
    }
    let pos;
    pos = targetEle.style.position;
    top = targetEle.style.top;
    targetEle.style.position = "relative";
    targetEle.style.top = theTop + "px";
    targetEle.scrollIntoView({ behavior: "smooth", block: "start" });
    targetEle.style.top = top;
    targetEle.style.position = pos;
  }

  function ScrollToResolver(elem) {
    elem.scrollIntoView({
      behavior: "smooth", // smooth scroll
      block: "start", // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
    });
  }

  var secondary_nav_links = document.querySelectorAll('a[href^="#"]');
  if (secondary_nav_links.length > 0) {
    forEach(secondary_nav_links, function (i, link) {
      link.addEventListener("click", function (e) {
        e.preventDefault();
        AnchorScrollTo(link.getAttribute("href").substring(1));
      });
    });
  }

  // if (document.querySelectorAll('.tabbed-nav').length > 0) {

  //   var tabs = document.querySelectorAll('.tab'),
  //       listFilter = document.querySelector('.list-container');

  //   var filter = new Wallop(listFilter, {
  //     itemClass: 'list-item',
  //     currentItemClass: 'list-item-current'
  //   })

  //   for (let i = 0; i < tabs.length; i++) {

  //     tabs[i].addEventListener('click', function(event) {
  //       event.preventDefault();
  //       forEach(tabs, function(i,tab){removeClass(tab, 'active')})
  //       addClass(tabs[i], 'active');
  //       filter.goTo(i);
  //     })
  //   }
  // }

  // if (document.querySelectorAll('.flickity-tabs').length > 0) {

  //   var tabsContainer = new Flickity('.flickity-tabs', {
  //     pageDots: false,
  //     draggable: false,
  //     contain: true,
  //     cellAlign: 'left',
  //     watchCSS: true
  //   });
  // }
});

/* Init Choices.js */
/*******************/

if (document.querySelectorAll("select").length > 0) {
  var choices = new Choices("select", {
    placeholder: true,
    search: false,
    shouldSort: false,
  });
}

/* Accordion */
/*******************/

function toggleAccordion(event) {
  event.preventDefault();
  let target_id;
  target_id = event.currentTarget.getAttribute("href");

  if (target_id == null) {
    target_id = event.currentTarget.getAttribute("data-target");
  }

  var $target = document.getElementById(target_id.substring(1));
  if (hasClass($target, "is-hidden")) {
    addClass(event.target, "open");
    removeClass($target, "is-hidden");
    addClass($target, "is-shown");
  } else if (hasClass($target, "is-shown")) {
    removeClass(event.target, "open");
    removeClass($target, "is-shown");
    addClass($target, "is-hidden");
  }
}

ready(function () {
  var $accordion_triggers = document.querySelectorAll(".js-accordion-control");
  forEach($accordion_triggers, function (i, trigger) {
    trigger.addEventListener("click", toggleAccordion, false);
    let trigger_id = trigger.getAttribute("data-target");
    let target = document.getElementById(trigger_id.substring(1));
    addClass(target, "js-accordion-content is-hidden");
  });

  var grid = document.querySelector(".masonry-grid");
  if (grid !== null) {
    var msnry;

    imagesLoaded(grid, function () {
      // init Isotope after all images have loaded
      msnry = new Masonry(grid, {
        horizontalOrder: true,
        itemSelector: ".item",
        columnWidth: ".item-sizer",
        gutter: ".gutter-sizer",
        percentPosition: true,
      });
    });
  }
});

/* Sharing */
/*******************/

function socialPopup(url, width, height) {
  var left = screen.width / 2 - width / 2;
  var top = screen.height / 2 - height / 2;
  window.open(
    url,
    "",
    "menubar=no, toolbar=no, resizable=yes, scrollbars=yes, width=" +
      width +
      ", height=" +
      height +
      ", top=" +
      top +
      ", left=" +
      left
  );
}

ready(function () {
  var socialLinks = [].slice.call(document.querySelectorAll(".social-share"));

  forEach(socialLinks, function (i, link) {
    link.addEventListener("click", function (event) {
      event.preventDefault();
      socialPopup(this.href, 500, 500);
    });
  });
});

/*
/* CTA Banner */
/*******************/

if (document.querySelectorAll("#cta-banner").length > 0) {
  var close = document.querySelector("#close");
  close.addEventListener("click", function (event) {
    document.getElementById("cta-banner").style.display = "none";
  });
}
